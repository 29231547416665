<template>
  <el-dialog destroy-on-close class='dialog-site-edit cereb-dialog' title="Edit Site" :visible="visible" @update:visible="onClose" width="500px" @open="onDialogOpen" top="120px">
    <el-form class="cereb-form" :model="formData" :rules="rules" ref="editForm" label-position="left" label-width="140px">
      <el-form-item prop="sites" label="Number of Sites:">
        <el-input-number class="full-width cereb-input-number" placeholder="Type Organization name" v-model="formData.sites" controls-position="right" :min="1" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="onClose">Cancel</el-button>
      <el-button type="primary" @click="submitForm" :loading='isLoading'>Confirm</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: ['visible', 'value', 'input'],
  data() {
    return {
      isLoading: false,
      formData: {
        sites: 1,
      },
      rules: {
        sites: [{
          required: true,
          message: 'Number of site is required',
          trigger: ['change', 'blur']
        }],
      },
      expirationTimeValue: '',
    };
  },
  methods: {
    onDialogOpen() {
      this.formData.sites = this.value;
    },
    async submitForm() {
      await this.$refs.editForm.validate();
      this.$emit('input', this.formData.sites);
      this.onClose();
    },
    onClose() {
      this.$refs.editForm.clearValidate();
      this.$emit('update:visible', false);
    }
  },
};
</script>
<style scoped>
.dialog-site-edit {
  text-align: left;
}

.dialog-site-edit .el-dialog__header {
  padding-top: 30px;
}

.dialog-site-edit .el-dialog__body {
  padding-bottom: 0;
  padding-top: 17px;
  padding-left: 30px;
  padding-right: 30px;
}

.dialog-site-edit .el-dialog__footer {
  padding-bottom: 30px;
}
</style>