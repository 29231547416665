<template>
  <div class="user-list">
    <div class="operation-panel">
      <div class="logo"><img src="@/assets/7-eleven.png" />7-Eleven</div>
      <div class="control-bar">
        <el-button class="cereb-button" @click="handleAdd" icon="el-icon-plus">
          Add User
        </el-button>
      </div>
    </div>
    <div class="operation-site-panel">
      <a href="javascript:void(0)" @click="showSiteDialog = true"><span>{{ sitesCount }}</span>&nbsp;Sites&nbsp;<i class="el-icon-edit"></i></a>
    </div>
    <div class="content">
      <div class="filter">
        <span><b>2,490</b> users</span>
        <div class="right-region">
          <label>Site:
            <el-select v-model="site" placeholder="Select" class="cereb-select">
              <el-option
                v-for="item in sites"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </label>
          <el-input prefix-icon="el-icon-search" v-model="keywords" placeholder="keywords" clearable class="cereb-input list-view-search-input" />
        </div>
      </div>
      <el-table height="auto" :empty-text="'No Data'" :data="displayTableData" v-loading="loadingUsers" element-loading-background="rgba(0, 0, 0, 0.3)" class="cereb-table full-width">
        <el-table-column prop="id" label="ID" width="120" :align="'center'" />
        <el-table-column prop="username" label="Account name" width="160" />
        <el-table-column prop="alias" label="Alias" :align="'center'" />
        <el-table-column prop="email" label="Email" />
        <el-table-column prop="site" label="Site" />
        <el-table-column prop="status" label="Status" :align="'center'">
          <template slot-scope="scope">
            <div class="indicator-wrapper">
              <div class="status-indicator-inactive"
                v-if="scope.row.status === 'expired'"></div>
              <div class="status-indicator-active"
                v-else-if="scope.row.status === 'active'">
              </div>
              <div class="status-indicator-inactive"
                v-else-if="scope.row.status === 'inactive'"></div>
              <div class="status-indicator-pending"
                v-else-if="scope.row.status === 'pending'"></div>
              <div class="status-text">{{ getStatusText(scope.row.status) }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="creationTime" label="Creation Time" :align="'center'" />
        <el-table-column prop="expirationTime" label="Expiration Time" :align="'center'" />
        <el-table-column label="Operations" width="200">
          <template slot-scope="scope">
            <div class="operation-wrap">
              <el-button class="status-operation-button" @click="updateStatus(scope.row)"
                type="text" size="small">
                {{ getStatusButtonText(scope.row.status) }}
              </el-button>
              <el-button class="operation-button" @click="handleEdit(scope.row)" type="text" size="small">Edit</el-button>
              <el-button class="operation-button" @click="handleDelete(scope.row)" type="text" size="small">Delete</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrap">
      <el-pagination class="cereb-pagination" background layout="prev, pager, next" @current-change="handleCurrentChange" :current-page.sync="currentPage" :pager-count="9" :page-size="pageSize" :total="totalItems" />
    </div>
    <DialogUserEdit :visible.sync="showUserDialog" :sites="sitesCount" :list="userPageData.list" :value="editFormData" @confirm="handleAppendItem" />
    <DialogSiteEdit :visible.sync="showSiteDialog" v-model="sitesCount" />
  </div>
</template>

<script>
import { MessageBox } from 'element-ui';
import DialogUserEdit from './components/DialogUserEdit.vue';
import DialogSiteEdit from './components/DialogSiteEdit.vue';
class UserModel {
  constructor({ id = null, username = '', alias = '', email = '', status = 'inactive', plan = '', creationTime = '', expirationTime = '', site = '#1' } = {}) {
    this.id = id;
    this.username = username;
    this.alias = alias;
    this.email = email;
    this.status = status;
    this.plan = plan;
    this.creationTime = creationTime;
    this.expirationTime = expirationTime;
    this.site = site
  }
}
export default {
  components: {
    DialogUserEdit,
    DialogSiteEdit
  },
  data: function () {
    return {
      keywords: "",
      showUserDialog: false,
      showSiteDialog: false,
      editFormData: {},
      site: '',
      sitesCount: 200,
      userPageData: {
        currPage: 1,
        list: [
          new UserModel({
            "id": 1,
            "username": "Tom",
            "alias": "Tom",
            "email": "xxX@gmail.com",
            "status": "active",
            "plan": "manager",
            "creationTime": "2026-05-03",
            "expirationTime": "2026-05-03"
          }),
          new UserModel({
            "id": 2,
            "username": "Tom",
            "alias": "Tom",
            "email": "xxX@gmail.com",
            "status": "active",
            "plan": "manager",
            "creationTime": "2026-05-03",
            "expirationTime": "2026-05-03"
          }),
          new UserModel({
            "id": 3,
            "username": "Tom",
            "alias": "Tom",
            "email": "xxX@gmail.com",
            "status": "active",
            "plan": "staff",
            "creationTime": "2026-05-03",
            "expirationTime": "2026-05-03"
          }),
          new UserModel({
            "id": 4,
            "username": "Tom",
            "alias": "Tom",
            "email": "xxX@gmail.com",
            "status": "active",
            "plan": "staff",
            "creationTime": "2026-05-03",
            "expirationTime": "2026-05-03"
          }),
          new UserModel({
            "id": 5,
            "username": "Tom",
            "alias": "Tom",
            "email": "xxX@gmail.com",
            "status": "inactive",
            "plan": "staff",
            "creationTime": "2016-05-03",
            "expirationTime": "2016-05-03"
          }),
          new UserModel({
            "id": 6,
            "username": "Tom",
            "alias": "Tom",
            "email": "xxX@gmail.com",
            "status": "pending",
            "plan": "staff",
            "creationTime": "2016-05-03",
            "expirationTime": "2016-05-03"
          }),
          new UserModel({
            "id": 7,
            "username": "Tom",
            "alias": "Tom",
            "email": "xxX@gmail.com",
            "status": "expired",
            "plan": "staff",
            "creationTime": "2026-05-03",
            "expirationTime": "2026-05-03"
          }),
        ],
        pageSize: 7,
        totalCount: 49,
        totalPage: 7,
      },
      adminSites: null,
      currentPage: 1,
      pageSize: 7,
      loadingUsers: false
    }
  },
  computed: {
    totalItems() {
      return this.userPageData.totalCount
    },
    displayTableData() {
      let currentPageUsers = this.deepClone(this.userPageData.list)
      return currentPageUsers
    },
    sites() {
      return [
        { label: 'All', value: '' },
        ...Array.from({ length: this.sitesCount }, (v, i) => ({ label: `#${i + 1}`, value: i + 1 }))
      ];
    }
  },
  methods: {
    getStatusText(text) {
      if (text.substring(0, 7) === 'expired') {
        return text.substring(0, 7)
      } else {
        return text
      }
    },
    getStatusButtonText(text) {
      if (text.substring(0, 7) === 'expired') {
        return text.substring(8) === 'active' ? 'Deactivate' : 'Activate'
      } else {
        return text === 'active' ? 'Deactivate' : 'Activate'
      }
    },
    defaultErrorHandling(err) {
      console.log(err)
      if (err === 'invalid auth') {
        this.$message.error({ center: true, message: 'invalid salto authentication' });
      } else if (err === 'unauthorized') {
        this.$message.error({ center: true, message: 'login expired' });
        this.logout()
        // } else if (err === 'username exists') {
        //     this.$message.error({center: true, message: 'username exists'});
      } else {
        this.$message.error({ center: true, message: 'network error' });
      }
    },
    handleCurrentChange() {
      this.loadUsers()
    },
    async loadUsers() {
      this.loadingUsers = true
      try {
        // const userPageData = await api.getAdminPlatformUsers(null, this.currentPage, this.pageSize);
        // this.currentPage = userPageData.currPage;
        // this.userPageData = userPageData;
      } catch (err) {
        this.defaultErrorHandling(err);
      } finally {
        this.loadingUsers = false;
      }
    },
    async updateStatus(user) {
      user.status = user.status === 'active' ? 'inactive' : 'active';
    },
    handleAppendItem(data) {
      const index = this.userPageData.list.findIndex(user => user.id === data.id);
      if (index !== -1) {
        this.userPageData.list.splice(index, 1, data);
      } else {
        this.userPageData.list.push(data);
      }
    },
    handleAdd() {
      this.editFormData = new UserModel()
      this.showUserDialog = true;
    },
    handleEdit(row) {
      var index = (this.userPageData.list || []).findIndex((x) => x.id === row.id);
      var editItem = this.userPageData.list[index]
      Object.assign(this.editFormData, editItem)
      this.showUserDialog = true;
    },
    async handleDelete(row) {
      try {
        await MessageBox.confirm('<div style="font-size: 15px; margin-left:12px; margin-bottom:40px;">Delete?</div>', '', {
          customClass: "msgBox",
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          dangerouslyUseHTMLString: true
        });
        const index = this.userPageData.list.findIndex(user => user.id === row.id);
        if (index !== -1) {
          this.userPageData.list.splice(index, 1);
        }
        this.$message.success({ center: true, message: 'success' });
      } catch (error) {
        if (error !== 'cancel') {
          this.$message.error({ center: true, message: 'fail' });
        }
        console.log('delete cancel.');
      }
    },
    dialogEditHandle(e) {
      let type = e
      console.log('type: %s', e)
      // let type = e[0]
      // let data = e[1]
      if (type === 'cancel') {
        console.log("dialogEditHandle: cancle.")
      }
      else if (type === 'confirm') {
        console.log("dialogEditHandle: confirm.")
        this.loadUsers()
        // updateUserDetail(data).then(() => {
        //     this.$message.success('success');
        // }).catch((err) => {
        //     this.defaultErrorHandling(err)
        // }).finally(() => {
        //     this.showUserDialog = false
        //     this.loadUsers()
        // })
      }
      else if (type === 'close') {
        console.log("dialogEditHandle: close.")
      }
      this.showUserDialog = false;
    },
    formatTime(preTime) {
      if (preTime === null) {
        return 'null'
      }
      var t = new Date(preTime)
      var time = t.getFullYear() + '-' + (t.getMonth() + 1 < 10 ? "0" + (t.getMonth() + 1) : t.getMonth() +
        1) + '-' + (t.getDate() < 10 ? "0" + t.getDate() : t.getDate()) + ' ' + (t.getHours() < 10 ?
          "0" + t
            .getHours() : t.getHours()) + ':' + (t.getMinutes() < 10 ? "0" + t.getMinutes() : t
              .getMinutes()) + ':' + (t.getSeconds() < 10 ? "0" + t.getSeconds() : t.getSeconds());
      return time
    },

    deepClone(obj) {
      var _obj = JSON.stringify(obj),
        objClone = JSON.parse(_obj);
      return objClone;
    },
  },
  mounted: function () {
    console.log('start............')

    this.loadUsers()
  }
};
</script>

<style>
.user-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 25px 117px;
}

.user-list .operation-panel {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-size: 28px;
  color: rgba(255, 255, 255, 0.85);
}

.user-list .operation-panel .logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.user-list .operation-site-panel {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0 86px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.user-list .operation-site-panel a {
  color: rgba(255, 255, 255, 0.85);
  text-decoration: none;
}

.user-list .operation-site-panel a:hover {
  text-decoration: underline;
}

.user-list .control-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-list .content {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  background-color: #182130;
  margin: 10px 0;
  padding: 10px 0;
}


.user-list .content .filter {
  color: rgba(255, 255, 255, 0.85);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 0 20px;
}

.user-list .content .filter .right-region {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.user-list .content .filter .right-region .list-view-search-input {
  width: 360px;
}

.user-list .cereb-table {
  flex: 1 1 0;
}

.user-list .operation-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0 10px;
}

.user-list .pagination-wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.user-list .indicator-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.user-list .status-indicator-active {
  width: 6px;
  height: 6px;
  background: #52C41A;
  border-radius: 50%;
}

.user-list .status-indicator-inactive {
  width: 6px;
  height: 6px;
  background: #FF4141;
  border-radius: 50%;
}

.user-list .status-indicator-pending {
  width: 6px;
  height: 6px;
  background: #CCCCCC;
  border-radius: 50%;
}

.user-list .status-indicator-else {
  width: 6px;
  height: 6px;
  background: #ffc107;
  border-radius: 50%;
}
</style>
