<template>
  <el-dialog destroy-on-close class='edit-user-dialog cereb-dialog' :title="computedTitle" :visible="visible" @update:visible="onClose" @open="onDialogOpen" width="500px">
    <el-form class="cereb-form" :model="formData" :rules="rules" ref="editForm" label-position="left" label-width="170px">
      <el-form-item prop="plan" label="Role:">
        <el-checkbox v-for="plan of userPlanList" :label="plan.charAt(0).toUpperCase() + plan.slice(1)" :value="plan === formData.plan" @change="onChangePlan(plan)" :key="plan" />
      </el-form-item>
      <el-form-item prop="username" label="Account Name:" :rules="accountNameRules">
        <el-input v-if="isAdd" class="cereb-input" placeholder="Type account" v-model="formData.username" />
        <div v-else>{{ formData.username }}</div>
      </el-form-item>
      <el-form-item prop="alias" label="Alias:">
        <el-input class="cereb-input" placeholder="Type alias" v-model="formData.alias" />
      </el-form-item>
      <el-form-item v-if="isAdd" prop="email" label="Email:">
        <el-input class="cereb-input" placeholder="Type email" v-model="formData.email" />
      </el-form-item>
      <el-form-item label="Expiration Time:">
        <el-date-picker class="cereb-date-picker full-width" v-model="formData.expirationTime" :align="'left'" type="date" value-format="yyyy-MM-dd" placeholder="Please select a date" :picker-options="pickerOptions" />
      </el-form-item>
      <el-form-item label="Site:">
        <el-select v-model="formData.site" placeholder="Select" class="cereb-select full-width" :popper-append-to-body="false">
          <el-option
            v-for="item of sites"
            :key="item"
            :label="'#' + item"
            :value="item">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="onClose">Cancel</el-button>
      <el-button type="primary" @click="submitForm" :loading='isLoading'>Confirm</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { Dialog, Form, FormItem, DatePicker } from 'element-ui';

export default {
  components: {
    "ElDialog": Dialog,
    "ElForm": Form,
    "ElFormItem": FormItem,
    "ElDatePicker": DatePicker
  },
  props: ['list', 'visible', 'value', 'sites'],
  data() {
    return {
      isLoading: false,
      defaultFormData: {
        id: null,
        username: null,
        alias: '',
        email: '',
        expirationTime: null,
        creationTime: null,
        dataPoints: 0,
        plan: 'staff',
        site: null,
      },
      formData: {},
      userDetail: {},
      userPlanList: ['staff', 'manager'],
      rules: {
        plan: [{
          type: 'string',
          required: true,
          message: 'Role is required',
          trigger: 'blur'
        }],
        alias: [{
          type: 'string',
          required: true,
          message: 'Alias is required',
          trigger: 'blur'
        }],
        email: [{
          type: 'string',
          required: true,
          message: 'Email is required',
          trigger: 'blur'
        }, {
          type: 'string',
          pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          message: 'Invalid email format',
          trigger: ['change', 'blur']
        }],
      },
      formLabelWidth: '170px',
      labelPosition: 'left',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 3600 * 1000;
        },
      },
      expirationTimeValue: '',
    };
  },
  computed: {
    isAdd() {
      return this.formData.id === null || this.formData.id === undefined || this.formData.id === '';
    },
    computedTitle() {
      return this.isAdd ? 'Add User' : 'Edit User';
    },
    accountNameRules() {
      if (this.isAdd) {
        return [
          { required: true, message: 'Account Name is required', trigger: 'blur' },
          { min: 3, max: 20, message: 'Length should be 3 to 20', trigger: 'blur' },
        ];
      } else {
        return [];
      }
    },
  },
  methods: {
    onChangePlan(plan) {
      if (this.formData.plan == plan) {
        return;
      }
      this.formData.plan = plan;
    },
    defaultErrorHandling(err) {
      if (err === 'invalid auth') {
        this.$message.error({ center: true, message: 'invalid authentication' });
      } else if (err === 'unauthorized') {
        this.$message.error({ center: true, message: 'login expired' });
        this.logout()
      } else {
        this.$message.error({ center: true, message: 'network error' });
      }
    },
    onDialogOpen() {
      this.$nextTick(() => {
        if (this.value.id != null) {
          this.formData = Object.assign({}, this.value)
        }
        else {
          this.formData = Object.assign({}, this.defaultFormData)
        }
      });
    },
    async submitForm() {
      try {
        const valid = await this.$refs['editForm'].validate();
        if (valid) {
          if (this.formData.id == null) {
            this.formData.id = this.list.length + 1;
          }
          this.formData.creationTime = this.timeConvert(new Date(), 'date');
          this.$emit('confirm', { ...this.formData });
          this.$message.success('success');
          this.onClose();
        } else {
          console.log('error submit!!');
          return false;
        }
      } catch (error) {
        console.log('validation failed', error);
        return false;
      }
    },
    onClose() {
      this.$emit('update:visible', false)
      this.$refs['editForm'].clearValidate();
    },
    timeConvert(time, toType) {
      let date = new Date(time);
      if (toType === 'timestamp') {
        return date.getTime();
      } else if (toType === 'date') {
        let year = date.getFullYear();
        let month = ('0' + (date.getMonth() + 1)).slice(-2);
        let day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
      }
    },
  },
};
</script>
<style scoped>
.edit-user-dialog>.el-dialog__header {
  padding-top: 30px;
}

.edit-user-dialog>.el-dialog__body {
  padding-bottom: 0;
  padding-top: 17px;
  padding-left: 30px;
  padding-right: 30px;
}

.edit-user-dialog>.el-dialog__footer {
  padding-bottom: 30px;
}
</style>